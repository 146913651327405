export const dateFormat = {
  methods: {
    // 2021-12-31 00:00:00 식으로 오면 날짜만 짤라내는거
    dateFormat(date='-') {
      if(date == null || date == undefined){
        return '-';
      } else if (typeof date != 'string') {
        return;
      } else {
        return date.slice(0, 10).replaceAll('-','.'); // 앞에 10자리만 ex) 2021-12-31
      }
    },
  },
}