<template>
    <div class="content_container partner_detail seller_detail">
        <div class="inner">
            <div class="left_con">
                <!--left 판매자 소개 시작-->
                <div class="partner_wrap">
                    <div class="profile_wrap">
                        <div class="user">
                            <div class="user_image" v-if="this.providerData.save_name">
                                <img
                                    :src="this.providerData.path + this.providerData.save_name"
                                    :alt="this.providerData.original_name"
                                />
                            </div>
                            <div class="user_image" v-else>
                                <img src="@/assets/images/common/charactor_02.png" alt="프로필사진" />
                            </div>
                        </div>
                        <div class="text">
                            <div class="user_type">
                                <span class="label_provider">{{ this.providerData.user_division }}</span>
                                <p>{{ this.providerData.businessman_division }}</p>
                            </div>
                            <div class="user_class" :class="this.providerData.level_icon">
                                <p>{{ this.providerData.level }}</p>
                            </div>
                            <div class="user_name_wrap">
                                <h1 class="user_name">{{ this.providerData.name }}</h1>
                                <div class="active_wrap" :class="this.providerData.state == '정상' ? '' : 'unactive'">
                                    <p>
                                        {{ this.providerData.state != "정상" ? "비활동" : "활동 중" }}
                                    </p>
                                </div>
                            </div>
                            <div class="user_check_wrap">
                                <div class="user_check user_check_provider">
                                    <p>본인인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.providerData.user_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img v-if="this.providerData.user_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>

                                <div class="user_check user_check_provider">
                                    <p>사업자인증</p>
                                    <div
                                        class="btn_check"
                                        :class="
                                            this.providerData.businessman_certification_yn == 'false' ? 'disabled' : ''
                                        "
                                    >
                                        <img v-if="this.providerData.businessman_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>
                                <!-- 
                                <div class="user_check user_check_provider">
                                    <p>채널인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.providerData.channel_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img src="@/assets/images/common/icon_close_w.svg" />
                                        
                                    </div>
                                </div> -->

                                <div class="user_check user_check_provider">
                                    <p>거래역량인증</p>
                                    <div
                                        class="btn_check"
                                        :class="this.providerData.sales_certification_yn == 'false' ? 'disabled' : ''"
                                    >
                                        <img v-if="this.providerData.sales_certification_yn == 'false'" src="@/assets/images/common/icon_close_w.svg" />
                                        <img v-else src="@/assets/images/common/icon_check_w.svg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h1 class="subtitle">평가</h1>
                    <div class="estimate">
                        <div class="like_wrap">
                            <p>호감도</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="like_wrap">
                            <p>리뷰</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="like_wrap">
                            <p>활동</p>
                            <div class="star_wrap star_01">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_02">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_03">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_04">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                            <div class="star_wrap star_05">
                                <img src="@/assets/images/common/icon_star_border.svg" alt="별 외곽선" />
                                <img src="@/assets/images/common/icon_star.svg" alt="별" />
                            </div>
                        </div>
                    </div>

                    <h1 class="subtitle">소개</h1>
                    <div class="explain" id="explain">
                        <p>{{ this.providerData.introduce }}</p>
                        <!-- <span class="gt" @click="expandExplain">▼</span> -->
                    </div>

                    <h1 class="subtitle">해시태그</h1>
                    <div class="hashtag">
                        <div class="hashtag_word" v-for="(item, i) in this.providerData.hashtag" :key="i">
                            {{ item.hashtag }}
                        </div>
                        <p v-if="!this.providerData.hashtag || this.providerData.hashtag.length == 0">
                            해시태그가 없습니다
                        </p>
                    </div>

                    <div class="info_wrap">
                        <div class="info">
                            <h1>공급 카테고리</h1>
                            <span v-for="(item, i) in this.providerData.category" :key="i">{{ item.category }}</span>
                            <!-- <span v-if="this.providerData.category.length == 0">-</span> -->
                        </div>

                        <div class="info">
                            <h1>공급 경력</h1>
                            <p>{{ this.providerData.career }}</p>
                        </div>

                        <div class="info info_place">
                            <h1>활동지역</h1>
                            <span v-for="(item, i) in this.providerData.area" :key="i">{{ item.area }}</span>
                            <span v-if="this.providerData.area.length == 0">-</span>
                        </div>

                        <div class="info">
                            <h1>등록일</h1>
                            <p>{{ this.dateFormat(this.providerData.enrollment_date) }}</p>
                        </div>
                    </div>

                    <div class="btn_box_wrap">
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_store_g.svg" alt="icon_store" />
                                <img src="@/assets/images/common/icon_store_b.svg" alt="icon_store_hover" />
                            </div>
                            <p>스토어</p>
                        </div>
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_youtube_g.svg" alt="icon_youtube" />
                                <img src="@/assets/images/common/icon_youtube_b.svg" alt="icon_youtube_hover" />
                            </div>
                            <p>유튜브</p>
                        </div>
                        <div class="btn_box">
                            <div class="icon_wrap">
                                <img src="@/assets/images/common/icon_link_share_g.svg" alt="link_share" />
                                <img src="@/assets/images/common/icon_link_share_b.svg" alt="link_share_hover" />
                            </div>
                            <p>공유</p>
                        </div>
                    </div>
                </div>
                <!--left 판매자 끝 시작-->

                <!--left 판매자 등록매칭 시작-->
                <div class="matching_wrap">
                    <h1>
                        <span>"{{ this.providerData.name }}"</span>님께서 등록한 상품
                    </h1>
                    <div class="matching_list_wrap">
                        <div
                            class="matching_list"
                            v-for="(item, i) in this.providerData.product"
                            :key="i"
                            @click="this.$router.push(`/product/detail/${item.product_pk}`)"
                        >
                            <div class="product_image">
                                <img
                                    v-if="item.path && item.save_name"
                                    :src="item.path + item.save_name"
                                    :alt="item.original_name"
                                />
                                <div v-if="!item.path && !item.save_name" class="no_image">
                                    <img src="@/assets/images/common/no_image.svg" />
                                </div>
                                <span class="state" :class="item.state == '매칭중' ? '' : 'unactive'">{{
                                    item.state
                                }}</span>
                            </div>
                            <h2>
                                {{ item.name }}
                            </h2>
                        </div>
                        <div class="default" v-if="this.providerData.product.length == 0">등록한 상품이 없습니다.</div>
                    </div>
                </div>
                <!--left 판매자 등록매칭 끝-->
            </div>

            <!--right_fixed 시작-->
            <div class="right_fixed">
                <div class="text">
                    <h1>활동내역</h1>
                    <div class="line"></div>
                    <div class="scale_wrap">
                        <div class="scale">
                            <h2>거래 완료</h2>
                            <p>
                                <span>{{ this.numberFormat(this.providerData.deal_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>보낸 제안</h2>
                            <p>
                                <span>{{ this.numberFormat(this.providerData.request_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>받은 제안</h2>
                            <p>
                                <span>{{ this.numberFormat(this.providerData.response_count) }}</span
                                >건
                            </p>
                        </div>
                        <div class="scale">
                            <h2>등록 상품</h2>
                            <p>
                                <span>{{ this.numberFormat(this.providerData.product_count) }}</span
                                >개
                            </p>
                        </div>
                    </div>
                </div>
                <button type="button" class="sub_button" @click="this.$store.commit('alert', '준비중입니다')">
                    추천하기
                </button>
                
                <!--btn_left 누르면 옆에서 팝업 나타나기-->
                <div class="btn_left" @click="openRightFixed">
                    <span class="gt">&lt;</span>
                </div>
            </div>
            <!--right_fixed 끝-->
        </div>
    </div>
</template>

<script>
import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { sortDown } from "@/utils/sortItems";

export default {
    props: {
        id: String,
    },
    mounted() {
        this.fetchProviderData();
    },
    data() {
        return {
            providerData: {
                category: [],
                product: [],
                area: [],
                // 등등
            },
        };
    },
    mixins: [fetchData, numberFormat, dateFormat, sortDown],
    methods: {
        // 공급자 데이터 불러오기
        fetchProviderData() {
            this.fetchData(`/getProviderDetail/${this.id}`, (data) => {
                this.providerData = data.data;

                const finished = this.sortByDown(
                    data.data.product.filter((item) => item.state === "마감"),
                    "enrollment_date",
                );
                const matching = this.sortByDown(
                    data.data.product.filter((item) => item.state !== "마감"),
                    "enrollment_date",
                );
                this.providerData.product = matching.concat(finished);
            });
        },
        // 소개 펼치기
        expandExplain() {
            document.getElementById("explain").classList.toggle("expand");
        },
        openRightFixed(event){
            event.currentTarget.classList.toggle('open');
            const right_fixed = document.querySelector('.right_fixed');
            right_fixed.classList.toggle('open');
        }
    },
};
</script>

<style scoped src="@/assets/css/layout_provider.css"></style>
