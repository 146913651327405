const sortUp = {
  methods: {
    sortByUp: function (array, sortby) {
      if (sortby === 'finish_date') {
        const finished = array.filter(item => item.state === '마감');
        const matching = array.filter(item => item.state !== '마감');

        matching.sort((a, b) => {
          const aDate = new Date(a[sortby]);
          const bDate = new Date(b[sortby]);
          return aDate - bDate;
        });
        return matching.concat(finished);
      }
      return Array.from(array).sort((a, b) => {
        const aDate = new Date(a[sortby]);
        const bDate = new Date(b[sortby]);
        return aDate - bDate;
      });
    }
  }
};

const sortDown = {
  methods: {
    sortByDown: function (array, sortby) {
      if (sortby === 'finish_date') {
        const finished = array.filter(item => item.state === '마감');
        const matching = array.filter(item => item.state !== '마감');

        matching.sort((a, b) => {
          const aDate = new Date(a[sortby]);
          const bDate = new Date(b[sortby]);
          return bDate - aDate;
        });
        return matching.concat(finished);
      }
      
      return Array.from(array).sort((a, b) => {
        const aDate = new Date(a[sortby]).getTime();
        const bDate = new Date(b[sortby]).getTime();
        return bDate - aDate;
      });
    }
  }
};

export {
  sortUp,
  sortDown,
}